* {
  --border-color: #dededf;
  --width-main-menu: 230px;
  --bg-body: #fafbfe;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg-body);
  cursor: default;
  color: #6c757d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
code {
  padding: .2em .4em;
  margin: 0;
  font-size: 85%;
  background-color: #ddf4ff;
  color: #000000;
  font-family: monospace;
  border-radius: 0.25rem;
}

code.language-sh,
code.language-php {
  display: block;
  width: 100%;
  padding: .5rem 1rem;
}

.loading-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-data {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.for-hover-opacity { opacity: .7 !important; }
.for-hover-opacity:hover { opacity: 1 !important; }

.for-hover {
  cursor: pointer;
}
.for-hover-link {
  cursor: pointer;
}
.for-hover-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}

.button-icon,
.button-icon-disabled {
  opacity: .7 !important;
}
.button-icon:hover {
  cursor: pointer;
  opacity: 1 !important;
}


hr {
  border: none;
  border-top: 1px solid var(--border-color);
  margin: 1rem auto;
}

.ui-alerts {
  width: 400px;
}

.block-card {
  border: none;
  -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
}
.block-card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: .25rem;
  padding: 1.5rem;
}

.page-title-box .page-title {
  font-size: 18px;
  margin: 0;
  line-height: 75px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #6c757d;
}

.opacity-0 { opacity: 0 !important; }
.opacity-10 { opacity: .1 !important; }
.opacity-20 { opacity: .2 !important; }
.opacity-30 { opacity: .3 !important; }
.opacity-40 { opacity: .4 !important; }
.opacity-50 { opacity: .5 !important; }
.opacity-60 { opacity: .6 !important; }
.opacity-70 { opacity: .7 !important; }
.opacity-80 { opacity: .8 !important; }
.opacity-90 { opacity: .9 !important; }

.position-absolute-border {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.slide-to-right {
  animation: slide-to-right .2s;
}
@keyframes slide-to-right {
  from { opacity: 1; }
  to { transform: translate(100px); opacity: 0; }
}

.slide-out-left {
  animation: slide-out-left .2s;
}
@keyframes slide-out-left {
  from { transform: translate(-100px); opacity: 0; }
  to { opacity: 1; }
}

.pre {
  border-radius: 4px;
  color: #222;
  font-size: 14px;
  padding: 5px 8px;
  margin: 0 0 20px;
  white-space: pre;
  word-wrap: normal;
  overflow-x: auto;
  line-height: 24px;
  font-weight: 100;
}
.pre.border {
  background: rgba(34,34,34,0.03);
  border: 1px solid rgba(34,34,34,0.2);
}

.global-error {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffe9e9d4;
  text-align: center;
  padding: 1rem 5rem;
  color: #500808;
}
.global-error-code {
  font-size: 10rem;
  line-height: 10rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: 700;
}
.global-error-message {
  font-size: 1.2rem;
  line-height: 1.3rem;
  font-weight: 500;
}
.global-error-remark {
  margin: 1rem auto 0;
  max-width: 400px;
}

.god-mode-global-off {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: .5rem 1rem;
  background-color: #fc0;
  border-top: 1px solid var(--border-color);
  z-index: 10000;
}

.hidden-sticky {
  position: absolute;
  background: var(--bg-body);
  top: -50px;
  left: -1px;
  right: -1px;
  bottom: calc(100% + 1px);
  z-index: -1;
}

.hidden-sticky::before {
  content: "";
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  top: 100%;
  left: 0;
  background: radial-gradient(circle at bottom right, transparent 0.5rem, var(--bg-body) 0.5rem);;
  z-index: -1;
}

.hidden-sticky::after {
  content: "";
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  top: 100%;
  right: 0;
  background: radial-gradient(circle at bottom left, transparent 0.5rem, var(--bg-body) 0.5rem);
  z-index: -1;
}

.slider-menu {
  overflow-y: auto;
  z-index: 1000;
}

.header-menu-button {
  display: none !important;
}

@media screen and (max-width: 1365px) {
  .header-menu-button {
    display: flex !important;
  }
  .request-main-menu {
    position: fixed;
    z-index: 1000;
    left: -300px;
    transition: .1s;
  }
  .slider-menu {
    transition: .1s;
    top: 0 !important;
    left: -300px !important;
  }
  .slider-menu-show {
    left: 0 !important;
  }
  .admin-content-body {
    margin-left: 0 !important;
  }
}

.ui-alerts > div {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
}
