
#requests-block {
    flex-grow: 1;
}

.fields input,
.request-edit-cell-body input {
    font-size: 1rem !important;
    max-height: 38px;
}

.my-large {
    width: 90% !important;
    max-width: 1200px !important;
}

.request-row > td {
    position: relative;
    font-weight: 100;
}
#requests-header-row > th,
.request-row > td {
    padding: .5rem !important;
}

.request-cell-edit,
.request-cell-edit-in-block {
    padding: 2px;
    opacity: 0;
    position: absolute;
    top: 50%;
    right: 2px;
    margin-top: -6px;
    line-height: 12px;
    /* background-color: #ffffff8e; */
    z-index: 100;
    border-radius: .25rem;
    cursor: pointer;
    transition: .2s;
}

.request-row > td:hover .request-cell-edit,
.cell-block:hover .request-cell-edit-in-block {
    opacity: 1;
}

.request-cell-edit > i.icon,
.request-cell-edit-in-block > i.icon {
    opacity: .6;
    font-size: 12px;
    margin: 0 !important;
}

.request-cell-edit:hover > i.icon,
.request-cell-edit-in-block:hover > i.icon {
    opacity: 1;
}

.request-edit-cell-modal {
    display: none;
}
.request-edit-cell-modal.show {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: #ffffff;
    border-radius: .25rem;
    border: 1px solid var(--border-color);
    z-index: 100;
    width: 230px;
}

.request-edit-cell {
    position: fixed;
    top: 0;
    left: 0;
    background: #ffffff;
    border-radius: .25rem;
    border: 1px solid var(--border-color);
    z-index: 100;
    width: 230px;
}

.request-edit-cell-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 27px;
    padding: .25rem .5rem;
    border-bottom: 1px solid var(--border-color);
    font-weight: 700;
}
.request-edit-cell-header i.icon {
    opacity: .6;
    cursor: pointer;
}
.request-edit-cell-header i.icon:hover {
    opacity: 1;
}
.request-edit-cell-body {
    padding: .5rem .5rem;
}

.request-edit-cell-body .disabled.field {
    opacity: 1 !important;
}

.request-edit-cell-body .ui.form.tiny input {
    font-size: .85714286rem !important;
    line-height: .85rem !important;
    background: #61a200 !important;
}

.my-form .disabled {
    opacity: .7 !important;
}

.pin-btn-select {
    width: 90px;
    padding: .7rem .5rem !important;
    margin: .25rem !important;
}

.dropdown-change-sector .error-message {
    white-space: normal !important;
}

.dropdown-change-sector.loading > .divider {
    margin-right: 8px;
}

.request-row-select-item {
    padding: .7rem 1.1rem!important;
    white-space: normal;
    word-wrap: normal;
    cursor: pointer;
    font-size: 100%;
}
.request-row-select-item.active {
    font-weight: 700;
}

.request-row {
    font-size: 90%;
    background: #fafdff;
}
.request-row:hover {
    background: #f5f8ff;
}

/** Оформление записи */
.request-row-theme-0 { background-color: #fafdff; color: #000000; }
.request-row-theme-0:hover:not(.story-request-row) { background-color: #f5f8ff; }

/** Оформление записи */
.request-row-theme-1 { background-color: #ffff81; color: #000000; }
.request-row-theme-1:hover:not(.story-request-row) { background-color: #ffff4f; }

/** Оформление слива */
.request-row-theme-2 { background-color: #bf4346; color: #ffffff !important; }
.request-row-theme-2:hover:not(.story-request-row) { background-color: #bd2c2e; }

/** Оформление слива */
.request-row-theme-3 { background-color: #8084e4; color: #ffffff !important; }
.request-row-theme-3:hover:not(.story-request-row) { background-color: #686cd8; }

/** Оформление Бракованой заявки */
.request-row-theme-4 { background-color: #656464; color: #ffffff !important; }
.request-row-theme-4:hover:not(.story-request-row) { background-color: #545454; }

/** Оформление Недозвона */
.request-row-theme-5 { background-color: #c7c7c7; color: #000000; }
.request-row-theme-5:hover:not(.story-request-row) { background-color: #bababa; }

/** Оформление Бесплатной консультации */
.request-row-theme-6 { background-color: #ffba01; color: #000000; }
.request-row-theme-6:hover:not(.story-request-row) { background-color: #f8b600; }

/** Оформление Прихода */
.request-row-theme-7 { background-color: #68ae00; color: #ffffff !important; }
.request-row-theme-7:hover:not(.story-request-row) { background-color: #61a200; }

/** Оформление Онлайн заявки */
.request-row-theme-8 { background-color: #8d52cb; color: #ffffff !important; }
.request-row-theme-8:hover:not(.story-request-row) { background-color: #8246c2; }

/** Оформление Онлайн прихода */
.request-row-theme-9 { background-color: #20aa8a; color: #ffffff !important; }
.request-row-theme-9:hover:not(.story-request-row) { background-color: #209b7e; }

/** Оформление Онлайн договора */
.request-row-theme-10 { background-color: #5790c1; color: #ffffff !important; }
.request-row-theme-10:hover:not(.story-request-row) { background-color: #4a83b5; }

/** Оформление Вторички */
.request-row-theme-11 { background-color: #795548; color: #ffffff !important; }
.request-row-theme-11:hover:not(.story-request-row) { background-color: #6f4b3e; }


.request-row-theme-2 .ui.loading.dropdown > i.icon:after,
.request-row-theme-3 .ui.loading.dropdown > i.icon:after,
.request-row-theme-4 .ui.loading.dropdown > i.icon:after,
.request-row-theme-7 .ui.loading.dropdown > i.icon:after,
.request-row-theme-8 .ui.loading.dropdown > i.icon:after,
.request-row-theme-9 .ui.loading.dropdown > i.icon:after,
.request-row-theme-10 .ui.loading.dropdown > i.icon:after,
.request-row-theme-11 .ui.loading.dropdown > i.icon:after {
    border-color: #fff #a7a7a7 #a7a7a7 !important;
}

.loader-requests {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    text-align: center;
    min-height: 19px;
    margin: .25rem auto .5rem;
}

.transition.updateRow {
    -webkit-animation-name: updateRow;
    animation-name: updateRow;
}

.request-row {
    transition-duration: .15s;
}
.updateRequestRow {
    opacity: .3 !important;
}
.createRequestRow {
    transform: scaleX(.9);
}

@-webkit-keyframes updateRow {
    10% { opacity: 0 !important; }
    100%  { opacity: 1 !important; }
}
@keyframes updateRow {
    10% { opacity: 0 !important; }
    100%  { opacity: 1 !important; }
}

.to-copy-text {
    transition: .1s;
}
.to-copy-text:hover {
    cursor: pointer;
    text-decoration: underline;
}
.to-copy-text.copyed {
    /* animation: updateRow .3s; */
    opacity: .3;
}

.call-to-request, .call-to-request:hover {
    color: inherit;
}

.search-form {
    display: flex;
    flex-direction: column;
    width: 220px;
    max-width: 220px;
}
.search-form > *:not(.dimmer) {
    margin: 0 .5rem .3rem !important;
}

.search-form > .dropdown {
    max-width: 206px !important;
}

.search-form .divider.text {
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    max-width: 160px !important;
}

.search-form .menu.transition {
    left: calc(.5rem - 1px) !important;
    right: calc(.5rem - 1px) !important;
}

.search-form .dropdown .menu.transition {
    margin-top: 35px !important;
    box-shadow: none !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.button-label-info {
    position: absolute;
    top: 0;
    right: 0;
}

.page-title-box {
    display: flex;
    align-items: center;
}

.page-title-box > *:not(:last-child) {
    margin-right: 1rem !important;
}

.page-title-subox > *:not(:last-child) {
    margin-right: .5rem !important;
}

.online-user {
    width: 8px;
    height: 8px;
    background-color: chartreuse;
    border-radius: 50%;
    margin-right: .3rem;
}

.wrap-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.wrap-cell-max {
    max-width: 150px;
}

.truncate-text-show, .truncate-text {
    overflow: hidden;
    line-height: 1.3em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
.truncate-text {
    -webkit-line-clamp: 3; 
    /* height: 3.8em; */
}

.counter-updated {
    animation-duration: .3s;
    animation-name: slidein;
}

@keyframes slidein {
    0% { transform: scale(.7); }
    25% { transform: scale(1.2); }
    50% { transform: scale(.7); }
    75% { transform: scale(1.2); }
    100% { transform: scale(1); }
}

.counter-new-data {
    color: #ffa500;
    font-weight: 900;
}

.button-icon-dropdown > i.icon {
    opacity: .7;
}

.button-icon-dropdown > i.icon:hover {
    opacity: 1;
}

.sms-row {
    position: relative;
    margin: .5rem 0;
    padding: .6rem 1rem 1rem;
    border: 1px solid #f0f0f0;
    border-radius: .5rem;
    font-size: 80%;
    max-width: 92%;
    background: #f8f8f8;
}

.sms-row-in {
    margin-left: auto;
}
.sms-row-out {
    margin-right: auto;
    background: #fffff4;
    border-color: #ffffde;
}

.sms-row .sms-title {
    padding-right: .6rem;
    font-weight: 700;
}

.sms-row .sms-failed {
    position: absolute;
    color: red;
    top: 50%;
    margin-top: -8px;
}

.sms-row.sms-row-in .sms-failed {
    left: -2rem;
}
.sms-row.sms-row-out .sms-failed {
    right: -2rem;
}

.sms-date {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 3px;
    right: 4px;
    font-size: 80%;
}

.sms-date :not(:last-child) {
    margin-right: .25rem;
}

.scrolling-sms {
    max-height: calc(70vh);
    overflow: auto;
}

.loading-messages {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .5;
    font-weight: 700;
}

.request-filter-date .ui.label.label {
    min-width: 43px;
    text-align: center;
}
.request-filter-date input {
    max-height: 36px;
}

.flash-cell {
    color: #ffffff;
    background: #ff2d2d;
    border-radius: .25rem;
    padding-left: .5rem;
    padding-right: .5rem;
}