.header-title {
    font-weight: 700;
    margin-right: 1rem;
    font-size: 150%;
    line-height: 150%;
    margin-top: 2px;
}

.header-menu {
    position: sticky;
    top: 0;
    min-height: 50px;
    padding: .5rem 1rem;
    background: #ffffff;
    border-bottom: 1px solid var(--border-admin-segment);
    z-index: 500;
}

.header-menu-link {
    margin: .2rem;
    opacity: .7;
}

.header-menu-link.active {
    opacity: 1;
    position: relative;
}

.header-menu-link.active:after {
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    content: "";
    display: block;
    height: 3px;
    background: #000000;
    opacity: .2;
}

.header-menu-icon {
    margin: 0 !important;
    font-size: 120% !important;
}

.header-rows {
    display: flex;
    align-items: center;
}

.header-rows .icon {
    margin: 0;
}

.header-rows > *:not(:last-child) {
    margin-right: .5rem; 
}

.btn-header-row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    margin: 0 !important;
}
.btn-header {
    position: absolute;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    background: none;
    font-size: 130%;
    line-height: 50px;
}
.btn-header:disabled {
    cursor: default;
}

.header-nav-btn {
    margin: 0 !important;
}
.header-nav-btn .icon {
    opacity: .5;
}
.header-nav-btn.active .icon {
    color: #3b81dc;
    opacity: 1;
}

.header-hover-text:hover {
    cursor: pointer;
    color: #3b81dc;
}

.btn-header:hover:not([disabled]) { background: #f0f2ff; }

.btn-header.btn-header-danger { color: #631717; background: #ffe0e0; }
.btn-header.btn-header-danger:hover { color: #631717; background: #ffd0d0; }

.btn-header > .label {
    position: absolute;
    top: 7px;
    right: 7px;
}
